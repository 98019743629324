import { createContext, useContext, useEffect, useState } from 'react'
import { getUserProfile } from 'api';

export const AuthContext = createContext();
export const AuthUpdateContext = createContext();

export const useAuth = () => {
    return {
        currentUser: useContext(AuthContext),
        setCurrentUser: useContext(AuthUpdateContext),
    }
}

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState({})

    useEffect(() => {
        (async () => {
            const userProfile = await getUserProfile();
            setCurrentUser(userProfile)
        })();
    }, [])

    return (
        <AuthContext.Provider value={currentUser}>
            <AuthUpdateContext.Provider value={setCurrentUser}>
                {children}
            </AuthUpdateContext.Provider>
        </AuthContext.Provider>
    )
}